import { Typography, Stack, styled } from '@mui/material';

export const StyledStack = styled(Stack)(({ theme }) => ({
  overflow: 'hidden',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: theme.spacing(10),

  padding: theme.spacing(20, 0),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(16, 0),
    gap: theme.spacing(4.5),
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(5),
    padding: theme.spacing(8, 0),
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(4),
  },
}));
export const MainWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(3),
  },
  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(6),
    paddingBottom: theme.spacing(3),
  },

  flex: 2,
}));

export const CaseStudyWrapper = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  flex: 1,
  gap: theme.spacing(1),
}));

export const StyledUnderlinedText = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  backgroundImage: 'linear-gradient(currentColor, currentColor)',
  backgroundPosition: '0% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '0% 1px',
  transition: 'background-size .2s',

  '&.Hovering': {
    backgroundSize: '100% 1px',
  },
}));
export const StyledDesc = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.SECONDARY,
  transition: 'color .2s',
  '&.Hovering': {
    color: theme.palette.blueColors?.PLUS_1,
  },
}));
export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(2),
  },
}));
export const StyledImage = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(3),
  marginTop: 'auto',
  width: '100%',
  height: 'auto',
  aspectRatio: '16 / 9',
  objectFit: 'cover',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
  },
}));

export const StyledMainText = styled(Typography)(({ theme }) => ({
  ...theme.mixins.neutralGradient,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));
export const TextStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(8),
  flex: 1,
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(2),
  },
}));
