import { Collapse, Stack, styled, Switch, Typography } from '@mui/material';
import { Button } from '../Button';
import sunsetIcon from '../../../assets/svg/RumbleFish23Theme/sunsetIcon.svg';
import astronomyMoonIcon from '../../../assets/webp/fromPNG/Rumblefish23Theme/astronomyMoon.webp';
import { Section } from '../Section';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { AnimatedLogo } from '../AnimatedLogo';
import { Link } from '../Link/Link';

export const HeaderSection = styled(Section)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  backgroundColor: theme.palette.background.default,
  zIndex: 1000,
}));

export const ImitateHeaderHeightStack = styled(Stack)(({ theme }) => ({
  height: '65px',
}));
export const StyledStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  height: '64px',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.utils.fluidSize({ minSize: 4, maxSize: 40 }),
  [theme.breakpoints.up('xl')]: {
    gap: theme.spacing(5),
  },
  ['div:last-of-type']: {
    marginLeft: 'auto',
  },
}));
export const StyledAnimatedLogo = styled(AnimatedLogo)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  cursor: 'pointer',
  transition: 'all 0.4s ease',
  [theme.breakpoints.down('md')]: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  ':not(:hover)': {
    '*': {
      animationPlayState: 'paused',
    },
  },
  ':hover': {
    transform: 'scale(1.2)',
  },
}));
export const SubmenusStack = styled(Stack)(({ theme }) => ({
  height: '100%',
  div: {
    display: 'flex',
    alignItems: 'center',
  },
  flexDirection: 'row',
  gap: theme.utils.fluidSize({ minSize: 4, maxSize: 40 }),
  [theme.breakpoints.up('xl')]: {
    gap: theme.spacing(5),
  },
}));
export const SubmenuName = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  '&.CurrentlySelectedSubmenu': {
    borderBottom: `2px solid ${theme.palette.blueColors?.PLUS_1}`,
  },
  a: {
    color: 'currentColor',
  },
  cursor: 'pointer',
  display: 'flex',
  gap: theme.spacing(1),
  borderBottom: '2px solid transparent',
  height: '100%',
  transition: 'color .35s, border .35s',
  color: theme.palette.invertedContent?.PRIMARY,
  alignItems: 'center',

  '&.WithoutSubmenu': {
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.blueColors?.PLUS_1}`,
    },
  },
}));
export const OptionsStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.utils.fluidSize({ minSize: 12, maxSize: 24 }),
  [theme.breakpoints.up('xl')]: {
    gap: theme.spacing(3),
  },
}));
export const StyledContactButton = styled(Button)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
}));
export const ThemeSwitch1 = styled(Switch)(({ theme }) => ({
  width: 46,
  height: 24,
  padding: theme.spacing(0, 0),
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transform: 'translateX(0px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url(${astronomyMoonIcon})`,
        backgroundSize: '66%',
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'rgba(255,255,255,0.1)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: 'rgba(255,255,255,0.1)',
    width: 24,
    height: 24,

    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(${sunsetIcon})`,
      backgroundSize: '66%',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: 'rgba(255,255,255,0.1)',

    borderRadius: '24px',
  },
}));

export const ThemeSwitch = styled(Switch)(({ theme }) => ({
  width: 46,
  height: 24,
  padding: theme.spacing(0, 0),

  '&.MuiSwitch-root': {
    overflow: 'visible !important',
  },
  '& .MuiSwitch-switchBase': {
    // margin: 1,
    padding: 0,
    transform: 'translateX(0px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url(${astronomyMoonIcon})`,
        backgroundSize: '66%',
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'rgba(255,255,255,0.1)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor:
      theme.palette.type === 'dark'
        ? 'rgba(255,255,255,0.1)'
        : 'rgba(27, 36, 44, 0.10)',
    width: 24,
    height: 24,

    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(${sunsetIcon})`,
      backgroundSize: '66%',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: 'rgba(27, 36, 44, 0.10)',
    borderRadius: '24px',
  },
}));

export const MobileMenuContentStack = styled(Stack)(({ theme }) => ({
  width: '100vw',
  height: 'calc(100vh - 65px)',
}));
export const MobileMenuBorderStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));
export const StyledMobileMenuCollapse = styled(Collapse)(({ theme }) => ({
  '&.MuiCollapse-wrapper': {
    overflow: 'auto',
    '& .MuiCollapse-wrapperInner': {
      '& .MuiBox-root': {
        height: 'auto',
        padding: '0px',
      },
    },
  },
  '& .MuiAccordionSummary-root': {
    minHeight: 'auto !important',
    padding: theme.spacing(1, 0),
  },
  width: 'calc((100vw - 100%) + 100%)',
  marginLeft: 'calc((100vw - 100%) / -2)',
}));

export const StyledMuiAccordion = styled(MuiAccordion)(({ theme }) => ({
  background: 'transparent',
  color: 'white',

  '&:before': {
    display: 'none',
  },
}));

export const IconStack = styled(Stack)(({ theme }) => ({
  width: '22px',
  height: '22px',

  svg: {
    transition: 'all .35s',
    position: 'absolute',
    '&.ShowIcon': {
      opacity: 1,
      transform: 'scale(1)',
    },
    '&.HideIcon': {
      opacity: 0,
      transform: 'scale(0)',
    },
  },
}));
export const StyledMuiAccordionSummary = styled(MuiAccordionSummary)(
  ({ theme }) => ({
    padding: theme.spacing(0, 0),

    '&.Transparent': {
      color: theme.palette.invertedContent?.TETRIARY,
    },
    '&.Opaque': {
      color: theme.palette.invertedContent?.PRIMARY,
    },
    '>div': { ...theme.mixins.mobilePadding() },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      margin: theme.spacing(0.5, 0),
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: 'currentColor',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
  })
);
export const StyledMuiAccordionDetails = styled(MuiAccordionDetails)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),

    '&.MuiAccordionDetails-root': {
      padding: '0 !important',
    },

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 0),
    },
  })
);

export const StyledAccordionWrapper = styled(Stack)(({ theme }) => ({
  overflow: 'auto',
  flex: 6,
}));
export const StyledButtonWrapper = styled(Stack)(({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}));
export const StyledTitle = styled(Typography)(({ theme }) => ({
  transition: 'color 0.35s',
  color: 'currentColor',
}));
export const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
}));

export const MobileContactButton = styled(Button)(({ theme }) => ({
  width: '90%',
}));

export const StyledSvgArrow = styled('svg')(({ theme }) => ({
  transform: 'rotate(180deg)',
  transition: 'transform .35s',
  '&.CurrentlySelectedSubmenu': {
    transform: 'rotate(0deg)',
  },
}));

export const MobileButtonWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(0.5),
  padding: theme.spacing(0.5, 1, 0.5, 1.5),
  borderRadius: theme.spacing(4),
  alignItems: 'center',
  border: `1px solid ${theme.palette.invertedContent?.PRIMARY}`,
}));
export const MobileButtonIconWrapper = styled(Stack)(({ theme }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
}));
export const MobileButtonText = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
}));
export const MobileButtonCloseIcon = styled(CloseRoundedIcon)(({ theme }) => ({
  position: 'absolute',
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  path: {
    color: theme.palette.invertedContent?.PRIMARY,
  },
  transition: 'opacity .35s, transform .35s',
}));
export const MobileButtonBurgerIcon = styled(MenuRoundedIcon)(({ theme }) => ({
  position: 'absolute',
  transition: 'opacity .35s, transform .35s',
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  path: {
    color: theme.palette.invertedContent?.PRIMARY,
  },
}));
export const StyledLogoLink = styled(Link)(({ theme }) => ({
  height: theme.spacing(5),
}));
export const StyledLogo = styled('img')(() => ({
  height: '100%',
}));
