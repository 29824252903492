import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import React, { useCallback, useContext } from 'react';

import { NextComponentsContext } from '../../../../context/nextComponentsContext/nextComponentsContext';
import blockchainEbook from '../../../assets/webp/fromPNG/Rumblefish23Theme/blockchainEbook.webp';
import debuggerEbook from '../../../assets/webp/fromPNG/Rumblefish23Theme/debuggerEbook.webp';
import workshopEbook from '../../../assets/webp/fromPNG/Rumblefish23Theme/workshopEbook.webp';
import { Loader } from '../Loader';
import { SubmenuBlock } from '../SubmenuBlock';
import type {
  EbookProps,
  DividerProps,
  ResourcesSubmenuType,
} from './ResourcesSubmenu.types';
import {
  EbookStack,
  ImageStack,
  LowerStack,
  UpperStack,
  StyledImage,
  StyledStack,
  ContentStack,
  DownloadStack,
  DividerSection,
  DividerWrapper,
  StyledEbookName,
  DividerSectionText,
  StyledDownloadText,
  StyledMobileVisitBlogIcon,
  StyledDesktopVisitBlogIcon,
} from './styles';

const Ebook = ({ link, imageSrc, headline }: EbookProps) => {
  const useRouter = useContext(NextComponentsContext)?.hooks?.useRouter;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter ? useRouter() : undefined;
  const handleOnClick = useCallback(() => {
    if (!link) return;
    if (router) router.push(link);
    else window.location.href = link;
  }, []);
  return (
    <EbookStack>
      <ImageStack>
        <StyledImage alt="ebookImage" src={imageSrc} />
      </ImageStack>
      <ContentStack>
        <StyledEbookName variant="strong_400">{headline}</StyledEbookName>
        <DownloadStack onClick={handleOnClick}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 17V3M20 21H4M17 12L11.999 17.001L6.999 12"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="currentColor"
            />
          </svg>

          <StyledDownloadText variant="strong_200">
            Download ebook
          </StyledDownloadText>
        </DownloadStack>
      </ContentStack>
    </EbookStack>
  );
};

export const Divider = ({
  children,
  linkTo,
  withoutIcon,
  ...props
}: DividerProps) => {
  const useRouter = useContext(NextComponentsContext)?.hooks?.useRouter;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter ? useRouter() : undefined;
  const handleOnClick = useCallback(() => {
    if (!linkTo) return;
    if (router) router.push(linkTo);
    else window.location.href = linkTo;
  }, []);
  const shouldHaveHover = !withoutIcon ? 'HoverPossibility' : '';
  return (
    <DividerSection onClick={handleOnClick} {...props}>
      <DividerWrapper className={shouldHaveHover}>
        <DividerSectionText variant="strong_100">{children}</DividerSectionText>
        {!withoutIcon && (
          <>
            <StyledMobileVisitBlogIcon />
            <StyledDesktopVisitBlogIcon
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.9698 9.03015L9 15M10.7789 9L14.9698 9.02955L15 13.2211"
                stroke="currentColor"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </StyledDesktopVisitBlogIcon>
          </>
        )}
      </DividerWrapper>
    </DividerSection>
  );
};

export const ResourcesSubmenu = ({
  blogPosts,
  ...props
}: ResourcesSubmenuType): ReactJSXElement => {
  const theme = useTheme();
  const postsQuantity = theme.utils.isMobile() ? 4 : 8;
  const postsToDisplay = blogPosts?.slice(0, postsQuantity) || null;

  return (
    <StyledStack>
      <Divider linkTo="/blog">Visit our blog</Divider>

      <UpperStack
        sx={
          !postsToDisplay
            ? { justifyContent: 'center', alignItems: 'center' }
            : undefined
        }>
        {!postsToDisplay && <Loader>Receiving blog posts</Loader>}
        {postsToDisplay &&
          postsToDisplay.map((post) => {
            const { attributes } = post;
            const category = attributes?.category && attributes?.category[0];
            const thumbnailIcon = attributes?.thumbnail
              ? attributes?.thumbnail?.data?.attributes?.url
              : attributes?.image?.data?.attributes?.url;
            return (
              <SubmenuBlock
                variant="resource"
                iconPrefix="Resources"
                type="square"
                key={post.id}
                name={attributes?.title || ''}
                caption={category?.name || ''}
                icon={thumbnailIcon || ''}
                linkTo={`/blog/post/${attributes?.slug || ''}`}
              />
            );
          })}
      </UpperStack>
      <Divider withoutIcon display={{ xs: 'none', md: 'flex' }}>
        CHECK ALL EBOOKS
      </Divider>

      <LowerStack>
        <Ebook
          link="/resources/fintech-mvp-guide/"
          imageSrc={debuggerEbook}
          headline="The Ultimate Guide to Building Your Fintech MVP"
        />
        <Ebook
          link="/resources/workshop"
          imageSrc={workshopEbook}
          headline="Discovery Workshop"
        />
        <Ebook
          link="/resources/blockchain"
          imageSrc={blockchainEbook}
          headline="Blockchain Trends in 2022"
        />
      </LowerStack>
    </StyledStack>
  );
};
