import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Stack } from '@mui/material';
import {
  Button,
  NameBanner,
  ZoomingImage,
} from '@rumblefish/ui/Rumblefish23Theme';
import aiBanner from '../../../public/webp/fromPNG/rumblefish23Theme/caseStudies/aiChatAssistant/aiAssistantBanner.webp';
import robotImage2 from '../../../public/webp/fromPNG/rumblefish23Theme/caseStudies/aiChatAssistant/aiChatAssistantSecondImage.webp';
import robotImage3 from '../../../public/webp/fromPNG/rumblefish23Theme/caseStudies/aiChatAssistant/aiChatAssistantThirdImage.webp';
import { latestCaseStudyData } from './latestCaseStudy.data';
import type { LatestCaseStudyProps } from './LatestCaseStudy.types';
import {
  CenterStack,
  InfoTitle,
  StyledContentStack,
  StyledDescription,
  StyledDescriptionStack,
  StyledImageSectionStack,
  StyledInfoStack,
  StyledStack,
  StyledTextStack,
  StyledTitle,
  LatestCaseStudySection,
  DesktopZoomingImage,
  MobileZoomingImage,
  GlowStack,
} from './styles';
export const LatestCaseStudy = ({
  latestCaseStudy,
  ...props
}: LatestCaseStudyProps): ReactJSXElement => {
  return (
    <LatestCaseStudySection width="normal">
      <GlowStack />
      <StyledStack {...props}>
        <NameBanner type="section" text={'Latest case study'} />

        <StyledTitle display={{ md: 'none' }} variant="display_sm">
          {latestCaseStudy.attributes?.title}
        </StyledTitle>

        <StyledContentStack>
          <StyledTextStack>
            <StyledTitle
              display={{ xs: 'none', md: 'block' }}
              variant="display_sm">
              {latestCaseStudy.attributes?.title}
            </StyledTitle>

            <StyledDescriptionStack>
              <StyledDescription variant="strong_500">
                {latestCaseStudy.attributes?.cardDescription}
              </StyledDescription>
              <Button
                variant="link"
                link={`/case-studies/${
                  latestCaseStudy.attributes?.slug || ''
                }`}>
                {latestCaseStudyData.buttonText}
              </Button>
            </StyledDescriptionStack>
          </StyledTextStack>
          <StyledImageSectionStack>
            <CenterStack>
              <ZoomingImage imgUrl={robotImage2} fromRightSideOn />
              <StyledInfoStack>
                <Stack>
                  <InfoTitle variant="strong_100">
                    {latestCaseStudyData.timeframeText}
                  </InfoTitle>
                  <StyledDescription variant="regular_300">
                    {latestCaseStudy.attributes?.collaborationTimeframe}
                  </StyledDescription>
                </Stack>
                <Stack>
                  <InfoTitle variant="strong_100">
                    {latestCaseStudyData.servicesText}
                  </InfoTitle>
                  <StyledDescription variant="regular_300">
                    {latestCaseStudy.attributes?.services}
                  </StyledDescription>
                </Stack>
              </StyledInfoStack>
            </CenterStack>
          </StyledImageSectionStack>
        </StyledContentStack>

        <DesktopZoomingImage imgUrl={aiBanner} />

        <MobileZoomingImage imgUrl={robotImage3} />
      </StyledStack>
    </LatestCaseStudySection>
  );
};
