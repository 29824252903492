import { ICaseStudy, LastCaseStudiesProps } from './LastCaseStudies.types';
import React, { useCallback, useContext, useState } from 'react';
import { NextComponentsContext } from '../../../../context/nextComponentsContext/nextComponentsContext';

import {
  CaseStudyWrapper,
  MainWrapper,
  StyledDesc,
  StyledImage,
  StyledMainText,
  StyledStack,
  StyledTitle,
  StyledUnderlinedText,
  TextStack,
} from './styles';
import { Button } from '../Button';
const CaseStudyBlock = ({ caseStudy }: { caseStudy: ICaseStudy }) => {
  const { description, image, slug, title } = caseStudy;
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const hoverClassName = isHovering ? 'Hovering' : '';
  const useRouter = useContext(NextComponentsContext)?.hooks?.useRouter;
  const router = useRouter ? useRouter() : undefined;
  const handleMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, []);

  const handleOnClick = useCallback(
    (linkTo: string) => {
      if (!linkTo) return;
      if (router) router.push(linkTo);
      else window.location.href = linkTo;
    },
    [router]
  );
  return (
    <CaseStudyWrapper
      onClick={() => handleOnClick(`/case-studies/${slug || ''}`)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <StyledDesc variant="strong_400" className={hoverClassName}>
        <StyledUnderlinedText
          className={hoverClassName}
          href={`/case-studies/${slug || ''}`}>
          {description}
        </StyledUnderlinedText>
      </StyledDesc>
      <StyledTitle variant="strong_600">{title}</StyledTitle>
      <StyledImage src={image} alt="lastCaseStudyBanner" />
    </CaseStudyWrapper>
  );
};
export const LastCaseStudies = ({
  firstCaseStudy,
  secondCaseStudy,
}: LastCaseStudiesProps) => {
  return (
    <StyledStack>
      <TextStack>
        <StyledMainText variant="display_sm">
          Check our case studies
        </StyledMainText>

        <Button
          sx={{ display: { xs: 'none', md: 'block' } }}
          link="/case-studies"
          variant="primary">
          More updates
        </Button>
      </TextStack>
      <MainWrapper>
        <CaseStudyBlock caseStudy={firstCaseStudy} />
        <CaseStudyBlock caseStudy={secondCaseStudy} />
      </MainWrapper>
      <Button
        sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
        link="/case-studies"
        variant="primary">
        More updates
      </Button>
    </StyledStack>
  );
};
